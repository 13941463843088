/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import CardContent from "@mui/material/CardContent";
import emailjs from "@emailjs/browser";

const validationSchema = yup.object({
  from_name: yup
    .string()
    .trim()
    .min(2, "Enter a valid full name")
    .max(50, "Enter a valid full name")
    .required("Specify your full name"),
  reply_to: yup
    .string()
    .trim()
    .email("Enter a valid email address")
    .required("Email is required"),
  subject: yup.string().trim().required("Type your subject"),
  message: yup
    .string()
    .trim()
    .min(5, "Enter in detail the service needed")
    .max(500, "Enter in detail the service needed")
    .required("Type your message"),
});

const Contact = () => {
  const initialValues = {
    from_name: "",
    reply_to: "",
    subject: "",
    message: "",
    to_name: "Millianfmr",
  };

  const onSubmit = () => {
    emailjs
      .send(
        "service_j5dazjl",
        "template_cenx86s",
        formik.values,
        "XIc-Wcdv9eGA1JOam"
      )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box data-aos={"fade-up"}>
      <Box marginBottom={2}>
        <Typography
          variant={"h4"}
          sx={{ fontWeight: 700 }}
          gutterBottom
          align={"center"}
        >
          Want to contact Millian?
        </Typography>
        <Typography color="text.secondary" align={"center"}>
          Fill this form with needed information for his services.
        </Typography>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={12} data-aos={"fade-up"}>
          <Box maxWidth={800} margin={"0 auto"}>
            <Card
              sx={{
                backgroundImage: "none",
                boxShadow: 4,
              }}
            >
              <CardContent>
                <form
                  noValidate
                  autoComplete="off"
                  onSubmit={formik.handleSubmit}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="Name"
                        name="from_name"
                        fullWidth
                        type="text"
                        value={formik.values.from_name}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.from_name &&
                          Boolean(formik.errors.from_name)
                        }
                        helperText={
                          formik.touched.from_name && formik.errors.from_name
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Email"
                        name="reply_to"
                        fullWidth
                        type="email"
                        value={formik.values.reply_to}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.reply_to &&
                          Boolean(formik.errors.reply_to)
                        }
                        helperText={
                          formik.touched.reply_to && formik.errors.reply_to
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Subject"
                        name="subject"
                        fullWidth
                        type="text"
                        value={formik.values.subject}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.subject &&
                          Boolean(formik.errors.subject)
                        }
                        helperText={
                          formik.touched.subject && formik.errors.subject
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-multiline-static"
                        label="Message"
                        name="message"
                        type="text"
                        multiline
                        rows={4}
                        fullWidth
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.message &&
                          Boolean(formik.errors.message)
                        }
                        helperText={
                          formik.touched.message && formik.errors.message
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Contact;
