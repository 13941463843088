import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useTheme } from "@mui/material/styles";
import ReactPlayer from "react-player/lazy";
import Typography from "@mui/material/Typography";

const mock = [
  {
    name: "Millian",
    title: "Quench my Fire",
    url: "https://youtu.be/5B1n05LhhoI?si=QdAIqFX8NNiqhiiZ",
  },
  {
    name: "Millian, J'ray",
    title: "Toronto",
    // url: "https://drive.google.com/file/d/10vg7UghYSzja04fpKjMDDgLXPBV4yxmn/view?usp=sharing",
    url: "https://youtu.be/XpxTvir02_g",
  },
  {
    name: "Millian ft. BM Casso",
    title: "Gbenusoun",
    // url: "https://drive.google.com/file/d/10vg7UghYSzja04fpKjMDDgLXPBV4yxmn/view?usp=sharing",
    url: "https://youtu.be/f33t1zaJCzs",
  },
  {
    name: "Millian",
    title: "Mama's Hope",
    url: "https://youtu.be/KroeVofitgU",
  },
  {
    name: "Millian ft. PK Bertram, BM Casso",
    title: "About You",
    url: "https://youtu.be/SJ9i8A98G7s",
  },
  {
    name: "Millian",
    title: "Vex",
    url: "https://youtu.be/WjJ2oa5VoqQ",
  },
];

const Catalog = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={5}>
        <Typography
          sx={{
            // textTransform: "uppercase",
            fontWeight: 700,
          }}
          gutterBottom
          align={"left"}
          variant={"h4"}
          // color="text.secondary"
        >
          Music Videos
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid item xs={12} sm={6} md={3} key={i}>
            <Box
              component={"a"}
              href={""}
              display={"block"}
              // width={1}
              // height={1}
              sx={{
                textDecoration: "none",
                transition: "all .2s ease-in-out",
                "&:hover": {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                // width={1}
                // height={1}
                data-aos={"fade-up"}
                data-aos-delay={i * 100}
                data-aos-offset={100}
                data-aos-duration={600}
                flexDirection={"column"}
                display={"flex"}
              >
                <Box>
                  <ReactPlayer
                    width="100%"
                    height="260px"
                    alignItems="center"
                    volume={0}
                    muted={true}
                    playing={true}
                    url={item.url}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
export default Catalog;
