import React from "react";
import Box from "@mui/material/Box";
import Main from "components/Layouts/Main";
import Container from "components/Layouts/Container";
import HomePage from "components/ui/HomePage";
import Contact from "components/ui/Contact";
import Catalog from "components/ui/Catalog";
import Songs from "components/ui/Songs";

const Dashboard = () => {
  return (
    <Main>
      <Box
        position={"relative"}
        sx={{
          // backgroundColor: theme.palette.alternate.main,
          marginTop: -13,
          paddingTop: 13,
        }}
      >
        <Container>
          <HomePage />
        </Container>
        <Container>
          <Catalog />
        </Container>

        <Container>
          <Songs />
        </Container>
        <Container>
          <Contact />
        </Container>
      </Box>
    </Main>
  );
};

export default Dashboard;
