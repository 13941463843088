import React from "react";
import Routes from "Routes";
import { BrowserRouter } from "react-router-dom";
import Page from "components/Layouts/Page";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "aos/dist/aos.css";
import "react-lazy-load-image-component/src/effects/blur.css";

function App() {
  return (
    <Page>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Page>
  );
}

export default App;
