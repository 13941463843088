import React from "react";
import Box from "@mui/material/Box";

const TopBar = () => {
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={1}
      style={{ height: "50px" }}
    >
      <Box display={"flex"} component="a" href="/" width={{ xs: 100, md: 130 }}>
        <Box
          component={"img"}
          src={
            "https://res.cloudinary.com/fmrtech/image/upload/v1673364282/FmrTech/Logo/white/1_1_goxhjc.png"
          }
          height={1}
          width={1}
        />
      </Box>
      <Box sx={{ display: { xs: "none", md: "flex" } }} alignItems={"center"}>
        {/*<Box>*/}
        {/*  <Link*/}
        {/*    underline="none"*/}
        {/*    component="a"*/}
        {/*    href="/songs"*/}
        {/*    color={"text.secondary"}*/}
        {/*  >*/}
        {/*    Songs*/}
        {/*  </Link>*/}
        {/*</Box>*/}
        {/*<Box marginLeft={4}>*/}
        {/*  <Link*/}
        {/*    underline="none"*/}
        {/*    component="a"*/}
        {/*    href="/gallery"*/}
        {/*    color={'text.secondary'}*/}
        {/*  >*/}
        {/*    Gallery*/}
        {/*  </Link>*/}
        {/*</Box>*/}
        {/*<Box marginLeft={4}>*/}
        {/*  <Button*/}
        {/*    variant="contained"*/}
        {/*    color="primary"*/}
        {/*    component="a"*/}
        {/*    target="blank"*/}
        {/*    // href="https://mui.com/store/items/the-front-landing-page/"*/}
        {/*    size="large"*/}
        {/*  >*/}
        {/*    Merch*/}
        {/*  </Button>*/}
        {/*</Box>*/}
      </Box>
    </Box>
  );
};

export default TopBar;
