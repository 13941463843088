import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import { IconButton } from "@mui/material";

const Footer = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box display={"flex"} component="a" href="/" width={60}>
          <Box
            component={"img"}
            src={
              "https://res.cloudinary.com/fmrtech/image/upload/v1673364281/FmrTech/Logo/white/1_phycj0.svg"
            }
            height={1}
            width={1}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={"center"}
          variant={"subtitle2"}
          color="text.secondary"
          gutterBottom
        >
          <IconButton
            size={"small"}
            color={"primary"}
            onClick={() =>
              window.open("https://instagram.com/millianfmr", "_blank")
            }
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            size={"small"}
            color={"primary"}
            onClick={() =>
              window.open("https://youtube.com/millianfmr", "_blank")
            }
          >
            <YouTubeIcon />
          </IconButton>
          <IconButton
            size={"small"}
            color={"primary"}
            onClick={() =>
              window.open("https://twitter.com/millianfmr", "_blank")
            }
          >
            <TwitterIcon />
          </IconButton>
        </Typography>
        <Typography
          align={"center"}
          variant={"subtitle2"}
          color="text.secondary"
          gutterBottom
        >
          &copy; {new Date().getFullYear()} Millian Fmr. All rights reserved
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
