import React from "react";
import Container from "components/Layouts/Container";
import Audio from "components/ui/Audio";

const Songs = () => {
  return (
    <Container>
      <Audio />
    </Container>
  );
};
export default Songs;
