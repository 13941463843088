import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";
import { TypeAnimation } from "react-type-animation";

const HomePage = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  return (
    <Grid container spacing={4}>
      <Grid item container xs={12} md={6} alignItems={"center"}>
        <Box data-aos={isMd ? "fade-right" : "fade-up"}>
          <Box marginBottom={2}>
            <Typography
              variant="h3"
              color="text.primary"
              sx={{ fontWeight: 700 }}
            >
              MILLIAN{" "}
              <Typography
                color={"primary"}
                component={"span"}
                variant={"inherit"}
                sx={{
                  background: `linear-gradient(180deg, transparent 82%, ${alpha(
                    theme.palette.secondary.main,
                    0.3
                  )} 0%)`,
                }}
              >
                <TypeAnimation
                  sequence={["FMR", 1000, "Feeling My Rhymes", 1000]}
                  cursor={false}
                  speed={40} // Custom Speed from 1-99 - Default Speed: 40
                  style={{ width: "0.5em" }}
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </Typography>
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h6"
              component="p"
              color="text.secondary"
              align={"left"}
              gutterBottom
              sx={{ fontWeight: 400 }}
            >
              Singer, Song Writer, Entertainer & Record Producer
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid
        item
        container
        alignItems={"center"}
        justifyContent={"center"}
        xs={12}
        md={6}
        data-aos="flip-right"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000"
      >
        <Box
          component={"img"}
          height={1}
          width={1}
          src={
            "https://res.cloudinary.com/fmrtech/image/upload/v1672378285/FmrTech/Illustrations/picture1_bwkpyu.jpg"
          }
          alt="..."
          effect="blur"
          boxShadow={5}
          borderRadius={4}
          maxWidth={360}
          sx={{
            filter: theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
          }}
        />
      </Grid>
    </Grid>
  );
};
export default HomePage;
