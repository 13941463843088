import React from "react";
import PropTypes from "prop-types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Link } from "@mui/material";

const mockLeftGrid = [
  {
    image:
      "https://res.cloudinary.com/fmrtech/image/upload/v1694432034/FmrTech/artwork/IMG_1981_a3qldw.png",
    url: "https://millianofficial.com/quench-my-fire",
    type: "Single",
    title: "Quench my Fire",
  },
  {
    image:
      "https://res.cloudinary.com/fmrtech/image/upload/v1673255557/FmrTech/artwork/IMG_3818_1_wqey7z.jpg",
    url: "https://ffm.to/millianjraytoronto",
    type: "Single",
    title: "Toronto",
  },
  {
    image:
      "https://res.cloudinary.com/fmrtech/image/upload/v1673255804/FmrTech/artwork/Gbono_wyckpk.png",
    url: "https://distrokid.com/hyperfollow/millian/gbono-feat-pk-bertram",
    type: "Single",
    title: "Gbono ft. PK Bertram",
  },
  {
    image:
      "https://res.cloudinary.com/fmrtech/image/upload/v1673254864/FmrTech/artwork/MAMA_S_HOPE_2_ciyhra.png",
    url: "https://distrokid.com/hyperfollow/millian/mamas-hope",
    type: "Single",
    title: "Mama's Hope",
  },
];

const mockMiddleGrid = [
  {
    image:
      "https://res.cloudinary.com/fmrtech/image/upload/v1683155328/FmrTech/artwork/paloma_artwork_wj3m1o.png",
    url: "https://millianofficial.com/paloma",
    type: "Single",
    title: "Paloma",
  },
  {
    image:
      "https://res.cloudinary.com/fmrtech/image/upload/v1673255806/FmrTech/artwork/feelingmyrhymes_gql86l.png",
    url: "https://distrokid.com/hyperfollow/millian/feeling-my-rhymes-4 ",
    type: "EP",
    title: "Feeling My Rhymes",
  },
  {
    image:
      "https://res.cloudinary.com/fmrtech/image/upload/v1673255804/FmrTech/artwork/Vex_jd7xl6.png",
    url: "https://vyd.co/Vex",
    type: "Single",
    title: "Vex",
  },
  // {
  //   image: "https://assets.maccarianagency.com/backgrounds/img8.jpg",
  //   description: "Quos dolores et quas molestias excepturi",
  //   title: "Accusantium doloremque",
  // },
];

const mockRightGrid = [
  {
    image:
      "https://res.cloudinary.com/fmrtech/image/upload/v1673255313/FmrTech/artwork/BBA492AB-1278-4CEB-BA9C-488791EE80E9_wdooue.jpg",
    url: "https://onerpm.link/836537175224",
    type: "Single",
    title: "Bia",
  },
  {
    image:
      "https://res.cloudinary.com/fmrtech/image/upload/v1673255804/FmrTech/artwork/About_You_bvmvkj.png",
    url: "https://distrokid.com/hyperfollow/millian/about-you-feat-pk-bertram--bm-casso",
    type: "Single",
    title: "About You ft. PK Bertram, BM Casso",
  },
  {
    image:
      "https://res.cloudinary.com/fmrtech/image/upload/v1673254864/FmrTech/artwork/GBENUSOUN_1_xvf4z2.png",
    url: "https://distrokid.com/hyperfollow/millian/gbenusoun-feat-bm-casso",
    type: "Single",
    title: "Gbenusoun ft. BM Casso",
  },
  // {
  //   image: "https://assets.maccarianagency.com/backgrounds/img9.jpg",
  //   description:
  //     "On the other hand, we denounce with righteous indignation and dislike",
  //   title: "Nemo enim ipsam",
  // },
];

const Column = ({ data }) => {
  const theme = useTheme();
  return (
    <Box>
      {data.map((item, i) => (
        <Link href={item.url} target="blank" key={i}>
          <Box
            sx={{
              marginBottom: { xs: 2, sm: 3 },
              "&:last-child": { marginBottom: 1 },
            }}
          >
            <Box
              boxShadow={1}
              sx={{
                position: "relative",
                overflow: "hidden",
                borderRadius: 2,
                "&:hover": {
                  "& img": {
                    transform: "scale(1.2)",
                  },
                  "& .portfolio-massonry__main-item": {
                    bottom: 0,
                  },
                },
                "& .lazy-load-image-loaded": {
                  display: "flex !important",
                },
              }}
            >
              <Box
                component={LazyLoadImage}
                height={1}
                width={1}
                src={item.image}
                alt="..."
                effect="blur"
                maxHeight={{ xs: 400, sm: 600, md: 1 }}
                sx={{
                  transition: "transform .7s ease !important",
                  transform: "scale(1.0)",
                  objectFit: "cover",
                  filter:
                    theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
                }}
              />
              <Box
                position={"absolute"}
                bottom={"-100%"}
                left={0}
                right={0}
                padding={4}
                bgcolor={"background.paper"}
                className={"portfolio-massonry__main-item"}
                sx={{ transition: "bottom 0.3s ease 0s" }}
              >
                <Box
                  component={"svg"}
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 1920 100.1"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    transform: "translateY(-90%)",
                    zIndex: 2,
                    width: 1,
                  }}
                >
                  <path
                    fill={theme.palette.background.paper}
                    d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                  />
                </Box>
                <Typography variant={"h6"} fontWeight={700} gutterBottom>
                  {item.type}
                </Typography>
                <Typography variant={"h6"} fontWeight={700} gutterBottom>
                  {item.title}
                </Typography>
                <Typography>{item.description}</Typography>
              </Box>
            </Box>
          </Box>
        </Link>
      ))}
    </Box>
  );
};

Column.propTypes = {
  data: PropTypes.array.isRequired,
};

const Audio = () => {
  return (
    <Box>
      <Box marginBottom={5}>
        <Typography
          sx={{
            // textTransform: "uppercase",
            fontWeight: 700,
          }}
          gutterBottom
          align={"left"}
          variant={"h4"}
          // color="text.secondary"
        >
          Released Songs
        </Typography>
        <Typography
          // variant="h6"
          // component="p"
          color="text.secondary"
          align={"left"}
          // gutterBottom
          // sx={{ fontWeight: 400 }}
        >
          Click each card to get access to the songs.
        </Typography>
      </Box>
      <Grid container spacing={4} data-aos={"zoom-in-down"}>
        <Grid item xs={12} md={4}>
          <Column data={mockLeftGrid} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Column data={mockMiddleGrid} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Column data={mockRightGrid} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Audio;
