import React from "react";
import Dashboard from "components/ui/Dashboard";
import Songs from "components/ui/Songs";
import Contact from "components/ui/Contact";

const routes = [
  {
    path: "/",
    renderer: (params = {}) => <Dashboard {...params} />,
  },
  {
    path: "/songs",
    renderer: (params = {}) => <Songs {...params} />,
  },
  {
    path: "/contact",
    renderer: (params = {}) => <Contact {...params} />,
  },
];

export default routes;
